<template>
  <UiPopup
    :model-value="modelValue"
    title="Attach file"
    size="small"
    class="!px-0"
    @update:model-value="emits('update:modelValue', false)"
  >
    <div class="overflow-hidden">
      <div class="styled-scrollbar mx-auto mb-3 flex max-h-[182px] flex-col gap-2 overflow-y-auto">
        <div v-for="(file, index) in files" :key="index">
          <UiInputFile id="upload-file" :model-value="file" :actions="false" class="[&>.group]:bg-primary-10" />
        </div>
      </div>
      <div class="mb-5">
        <p class="text-subhead-3 mb-0.5 text-left text-black-80">Description</p>
        <textarea
          id="description"
          ref="textarea"
          v-model="input"
          rows="1"
          placeholder="Add description message to the file"
          class="text-body-2 max-h-40 min-h-10 w-full resize-none overflow-y-hidden rounded-xl border-[1.5px] border-solid border-black-20 px-4 py-2 outline-none hover:border-primary-50 focus:border-primary-120 active:border-primary-120"
        />
      </div>
      <UiButtonBase id="send-message-image" class="ml-auto" icon @click="send()">
        <UiIcon name="arrow-big-right" />
      </UiButtonBase>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])
const { textarea, input } = useTextareaAutosize()

type Props = {
  modelValue: boolean
  files: any
}

defineProps<Props>()

const send = () => {
  emits('input', input.value)
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
